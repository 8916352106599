// Copyright © 2024 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

.gtw-status-panel
  height: 100%

  &-upper-container
    display: flex
    gap: $ls.xl
    justify-content: space-between

    .gtw-status-panel-upgrade-button
      background: var(--c-bg-brand-extralight)
      // stylelint-disable-next-line declaration-no-important
      color: var(--c-text-brand-normal) !important
      font-weight: $fw.bold
      box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, .09)
      margin-top: $cs.m
      width: fit-content

  &-lower-container
    display: flex
    gap: $ls.xl
    justify-content: space-between

  &-icon
    background-color: var(--c-bg-error-light)
    color: var(--c-text-error-normal)

  &-tooltip
    margin-bottom: -4px
    margin-left: 2px

  &-round-trip-time
    &-bar
      height: $cs.s
      width: 100%
      border-radius: $br.m
      position: relative
      padding-top: 2px
      padding-bottom: 2px
      margin-top: $cs.l
      margin-bottom: $cs.xxs
      overflow: hidden

      &-pointer
        width: $cs.xxs
        height: 100%
        top: 0
        position: absolute

    &-tag
      font-size: $fs.xxl
      font-weight: $fw.bold
      width: fit-content
      border-radius: $br.s

  &-transmissions
    display: flex
    align-items: center
    gap: $cs.xs
    margin-bottom: $cs.m
    font-size: $fs.s

    &:first-child
      margin-top: $cs.l

    &:last-child
      margin-bottom: 0

  &-duty-cycle
    display: flex
    align-items: center
    justify-content: space-between
    gap: $cs.m
    font-size: $fs.s
    margin-bottom: $cs.m

    &:first-child
      margin-top: $cs.l

    &:last-child
      margin-bottom: 0

.gtw-status-panel-divider
  border-bottom: 1px solid var(--c-border-neutral-light)
  margin-top: $cs.xl
  margin-bottom: $cs.xl
  height: 0px
  width: 100%

@container panel (max-width: 460px)
  .gtw-status-panel-duty-cycle
    gap: unset

  .gtw-status-panel-upper-container
    gap: $cs.xl

  .gtw-status-panel-lower-container
    gap: $cs.xl

@container panel (max-width: 350px)
  .section-title
    text-wrap: nowrap

  .gtw-status-panel-duty-cycle
    gap: unset
    line-height: 1

  .gtw-status-panel-transmissions
    margin-bottom: $cs.xs
