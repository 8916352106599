// Copyright © 2024 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

.side-footer
  margin-top: auto

  .cluster-button
    &-content
      display: flex
      width: 100%
      font-weight: $fw.bolder
      white-space: nowrap

  .support-button
    button
      width: fit-content

  &-button
    justify-content: space-between

  .side-footer-dropdown
    width: calc(19rem - 2px)
    min-width: initial
    max-width: initial

  .side-footer-version
    margin-left: $cs.xxs
    color: var(--c-text-neutral-semilight)
    font-weight: $fw.normal
    overflow: hidden
    text-overflow: ellipsis

  ul.side-footer-cluster-dropdown
    --dropdown-offset: $cs.m
    left: initial
    right: 0

    &-cluster-id
      right: $cs.m

.progress-bar
  height: $cs.xs
