// Copyright © 2024 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

.panel
  flex-grow: 1
  display: flex
  flex-direction: column
  position: relative
  padding: 0
  min-height: 28rem
  overflow: hidden
  & > :first-child
    padding: $cs.xl $cs.xl $cs.m $cs.xl

    +media-query($bp.md)
      padding: $cs.m

.content
  display: flex
  flex-direction: column
  flex-grow: 1
  position: relative
  height: 100%
  justify-content: center
