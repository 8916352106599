// Copyright © 2024 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

.panel
  height: 100%
  display: flex
  flex-direction: column
  position: relative

  & > :first-child
    flex-wrap: nowrap

  .content
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    height: 100%
    min-height: 10rem

.upseller
  height: 100%
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center

.upgrade-button
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, .09)
